import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./reducerSlice/userSlice";
import companySlice from "./reducerSlice/companySlice";
import deptSlice from "./reducerSlice/deptSlice";
import enquirySlice from "./reducerSlice/enquirySlice";
import reportSlice from "./reducerSlice/reportSlice";

export const store = configureStore({
    reducer: {
        auth: userSlice,
        createDept: deptSlice,
        company: companySlice,
        enquiry: enquirySlice,
        report:reportSlice,
    }
})