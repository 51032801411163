import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("ams_ecp");

// API headers including token
const getAuthHeaders = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});
export const getAllCompanydata = createAsyncThunk(
  "Companys/getAllCompanydata",
  async () => {
    try {
      const response = await BaseUrl.get(`/ecp/company`, getAuthHeaders());
      // console.log("response.data", response.data.data);
      return response.data.data;
    } catch (error) {
      // console.log(error);
      // console.log("error.response.data", error.response.data);
      return error.response.data;
    }
  }
);
export const createCompanyData = createAsyncThunk(
  "Companys/createCompanyData",
  async (data) => {
    try {
      const response = await BaseUrl.post(
        `/ecp/company/`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data)
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getCompDocdata = createAsyncThunk(
  "companys/getCompDocdata",
  async (id) => {
    try {
      // console.log("authtnk", getAuthHeaders())
      const response = await BaseUrl.get(
        `ecp/companyDocs/allDocs/${id}`,
        getAuthHeaders()
      );
      // console.log("responsedata=======>", response.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);
export const CreateCompDocdata = createAsyncThunk(
  "companys/CreateCompDocdata",
  async (data) => {
    try {
      const response = await BaseUrl.post(
        `ecp/companyDocs`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data)
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const updateCompdata = createAsyncThunk(
  "companys/updateCompdata",
  async (data) => {
    try {
      const response = await BaseUrl.patch(
        `ecp/company/`,
        data,
        getAuthHeaders()
      );
      // console.log("response.data", response.data)
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const getCompanyDropdata = createAsyncThunk(
  "companys/getCompanyDropdata",
  async () => {
    try {
      const response = await BaseUrl.get(
        `/ecp/company/companyDropDown`,
        getAuthHeaders()
      );
      // console.log("response.data", response.data.data)
      return response.data.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  }
);

export const getSingleCompanydata = createAsyncThunk(
  "Companys/getSingleCompanydata",
  async (id) => {
    try {
      const response = await BaseUrl.get(`ecp/company/${id}`, getAuthHeaders());
      // console.log("response.data", response.data.data[0])
      return response.data.data[0];
    } catch (error) {
      console.log(error);
    }
  }
);
export const getSingleCompDoc = createAsyncThunk(
  "companys/getSingleCompDoc",
  async (id) => {
    try {
      // console.log("id====>", id);
      const response = await BaseUrl.get(
        `ecp/companyDocs/${id}`,
        getAuthHeaders()
      );
      // console.log("company details===>", response.data)
      return response.data.data[0];
    } catch (error) {
      console.log(error);
    }
  }
);
export const updateCompDocdata = createAsyncThunk(
  "companys/updateCompDocdata",
  async (data) => {
    try {
      const response = await BaseUrl.patch(
        "ecp/companyDocs",
        data,
        getAuthHeaders()
      );
      // console.log("company docs===>", response.data)
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const companySlice = createSlice({
  name: "company",
  initialState: {
    CompanyData: [],
    compDocData: [],
    compDropData: [],
    singleCompanyData: {},
    singleCompDoc: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCompanydata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCompanydata.fulfilled, (state, action) => {
        state.loading = false;
        state.CompanyData = action.payload;
      })
      .addCase(getAllCompanydata.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error;
      });
    builder
      .addCase(getCompanyDropdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyDropdata.fulfilled, (state, action) => {
        state.loading = false;
        state.compDropData = action.payload;
      })
      .addCase(getCompanyDropdata.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error;
      });
    builder
      .addCase(getSingleCompanydata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleCompanydata.fulfilled, (state, action) => {
        state.loading = false;
        state.singleCompanyData = action.payload;
      })
      .addCase(getSingleCompanydata.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error;
      });
    builder
      .addCase(getCompDocdata.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompDocdata.fulfilled, (state, action) => {
        state.loading = false;
        state.compDocData = action.payload;
      })
      .addCase(getCompDocdata.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error;
      });
    builder
      .addCase(getSingleCompDoc.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSingleCompDoc.fulfilled, (state, action) => {
        state.loading = false;
        state.singleCompDoc = action.payload;
      })
      .addCase(getSingleCompDoc.rejected, (state, action) => {
        state.loading = true;
        state.error = action.error;
      });
  },
});

// SELECTORS
export const getCompanysData = (state) => state.company.CompanyData;
export const getCompDocData = (state) => state.company.compDocData;
export const getCompDropData = (state) => state.company.compDropData;
export const getSingleCompanysData = (state) => state.company.singleCompanyData;
export const getSingleCompDocument = (state) => state.company.singleCompDoc;
export const companyError = (state) => state.company.error;

// EXPORTS
export default companySlice.reducer;
