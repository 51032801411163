import { ArrowBackIosNewOutlined, MenuOutlined } from "@mui/icons-material";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import { Box, Tooltip, Typography } from "@mui/material";
// import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import LocationCityRoundedIcon from "@mui/icons-material/LocationCityRounded";
import SummarizeRoundedIcon from "@mui/icons-material/SummarizeRounded";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import AddBusinessRoundedIcon from "@mui/icons-material/AddBusinessRounded";
import React, { useEffect, useState } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { UseAuth } from "../customhooks/UseAuth";
import {
  fetchPermissions,
  getPermissions,
} from "../redux/reducerSlice/userSlice";
import { useDispatch, useSelector } from "react-redux";

const Items = ({ label, icon, color, onClickAction, active }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Tooltip title={label} placement="right-start">
      <div>
        <MenuItem
          active={active}
          style={{
            // color: color,
            // background: active ? '#f0f0f0' : (isHovered ? '#f0f0f0' : 'white'),// Change background color on hover
            color: active ? "#1976D2" : isHovered ? "#1976D2" : "black",
            borderStartStartRadius: 20,
            borderEndStartRadius: 20,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          icon={icon}
          onClick={onClickAction}
        >
          <Typography>{label}</Typography>
        </MenuItem>
      </div>
    </Tooltip>
  );
};

const SideBarComponent = () => {
  const dispatch = useDispatch();
  const { is_report, role } = UseAuth();
  const [permissions, setpermissions] = useState();
  const fetchpermissions = useSelector(getPermissions);
  const [show, setHide] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(fetchPermissions());
  }, [dispatch]);
  const handlePermissions = () => {
    // console.log("fetch permission===>", fetchpermissions);
    const jsonString = fetchpermissions.permisssions;
    // console.log("json string", jsonString);
    if (jsonString) {
      localStorage.setItem("permissions", jsonString);
      setpermissions(JSON.parse(jsonString));
      // console.log("permissions: ", permissions);
    }
  };
  useEffect(() => {
    if (fetchpermissions) {
      handlePermissions();
    }
  }, [fetchpermissions]);
  const handleNavigation = (path) => {
    navigate(path);
    setActiveItem(path); // Update the active item when navigating
  };
  useEffect(() => {
    // Extract the pathname from location object and set the active item accordingly
    setActiveItem(location.pathname);
  }, [location]);

  const logOutHandler = () => {
    navigate("/");
    localStorage.clear("ams_ecp");
  };
  return (
    <>
      <Box>
        <Sidebar
          collapsed={show}
          transitionDuration={500}
          // image='logo512.png'
          backgroundColor={"white"}
          rootStyles={{
            height: "100vh",
            boxShadow: "0 3px 3em #f0f0f0",
          }}
        >
          {!show && (
            <Box
              sx={{
                textAlign: "right",
                p: 1,
                cursor: "pointer",
                position: "sticky",
                top: "0em",
                bgcolor: "white",
              }}
            >
              <ArrowBackIosNewOutlined onClick={() => setHide(true)} />
            </Box>
          )}
          {show && (
            <Box
              sx={{
                textAlign: "center",
                p: 1,
                cursor: "pointer",
                position: "sticky",
                top: "0em",
                bgcolor: "white",
              }}
            >
              <MenuOutlined onClick={() => setHide(false)} />
            </Box>
          )}
          {!show && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "2em",
                p: 1,
                position: "sticky",
                top: "3em",
                bottom: "2em",
                zIndex: 99,
                bgcolor: "white",
                marginBottom: "1.5em",
              }}
            >
              <img
                src="/ams.png"
                alt="ams.png"
                style={{ width: 100, height: 100, marginTop: "-2em" }}
              />
              {/* <img src="/logo_2.jpeg" alt="logo_2.jpeg" style={{ width: "auto", height: 70, marginTop: "0.5em", border: "2px lightgrey solid", padding: "0 0.3em",boxShadow: "0 0 5px black" }} /> */}
            </Box>
          )}
          <Menu closeOnClick transitionDuration={500}>
            {/* {permissions?.side_nav.can_manage_dashboard && <Items label={'Dashboard'} icon={<DashboardRoundedIcon size={25} />} color={"black"} onClickAction={() => handleNavigation('/ecp')} active={activeItem === '/ecp'} />} */}
            {permissions?.side_nav.can_manage_users && (
              <Items
                label={"Manage Users"}
                icon={<PeopleRoundedIcon size={25} />}
                color={"black"}
                onClickAction={() => handleNavigation("/ecp/CompanyAdmin")}
                active={activeItem === "/ecp/CompanyAdmin"}
              />
            )}

            {permissions?.side_nav.can_manage_company && (
              <Items
                label={"Manage Company"}
                icon={<LocationCityRoundedIcon size={25} />}
                color={"black"}
                onClickAction={() => handleNavigation("/ecp/ManageComapany")}
                active={activeItem === "/ecp/ManageComapany"}
              />
            )}

            {permissions?.side_nav.can_manage_departments && (
              <Items
                label={"Manage Departments"}
                icon={<AddBusinessRoundedIcon size={25} />}
                color={"black"}
                onClickAction={() => handleNavigation("/ecp/DeptDetails")}
                active={activeItem === "/ecp/DeptDetails"}
              />
            )}
            {permissions?.side_nav.can_manage_enquiries && (
              <Items
                label={"Manage Enquires"}
                icon={<SummarizeRoundedIcon size={25} />}
                color={"black"}
                onClickAction={() => handleNavigation("/ecp/EnquiryDetails")}
                active={activeItem === "/ecp/EnquiryDetails"}
              />
            )}
            {is_report && role !== "SAdmin" && (
              <Items
                label={"Reports"}
                icon={<AssessmentRoundedIcon size={25} />}
                color={"black"}
                onClickAction={() => handleNavigation("/ecp/ReportDetails")}
                active={activeItem === "/ecp/ReportDetails"}
              />
            )}
            {/* <Items label={'Reports'} icon={<AssessmentRoundedIcon size={25} />} color={"black"} onClickAction={() => handleNavigation('/ecp/ReportDetails')} active={activeItem === '/ecp/ReportDetails'} /> */}
            <Items
              label={"Log Out"}
              icon={<ExitToAppRoundedIcon size={25} />}
              color={"black"}
              onClickAction={() => logOutHandler()}
            />
          </Menu>
          {!show && (
            <Typography
              sx={{
                position: "fixed",
                bottom: "1em",
                left: "1em",
                color: "black",
                fontWeight: "bold",
                fontSize: "1em",
              }}
              //   variant="h6"
            >
              Powered by
              <a
                href="https://www.ganakalabs.com/"
                target="_rebel"
                style={{ color: "blue" }}
              >
                {" "}
                ganakalabs.com
              </a>
            </Typography>
          )}
        </Sidebar>
      </Box>
    </>
  );
};

export default SideBarComponent;
