import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import SideBarComponent from "./components/SideBarComponent";
import TopBarComponent from "./components/TopBarComponent";
import { Suspense, lazy } from "react";
import { UseAuth } from "./customhooks/UseAuth";

const LoginComponent = lazy(() => import("./components/login/LoginComponent"));
const DashBoardComponent = lazy(() =>
  import("./components/dashboard/DashBoardComponent")
);

const CompanyAdminComponent = lazy(() =>
  import("./components/manageUsers/CompanyAdmin")
);
const AddCompanyAdmin = lazy(() =>
  import("./components/manageUsers/AddCompanyAdmin")
);
const UpdateCompanyAdmin = lazy(() =>
  import("./components/manageUsers/UpdateCompanyAdmin")
);
const ForgotPasswordComponent = lazy(() =>
  import("./components/forgotpassword/ForgotPassword")
);

const DeptDetailsComponent = lazy(() =>
  import("./components/managerDept/DeptDetails")
);
const AddDeptComponent = lazy(() => import("./components/managerDept/AddDept"));
const AddDocumentComponent = lazy(() =>
  import("./components/managerDept/AddDeptDocument")
);
const UpdateDocumentComponent = lazy(() =>
  import("./components/managerDept/UpdateDocument")
);
const UpdateDeptComponent = lazy(() =>
  import("./components/managerDept/UpdateDept")
);

const ManageCompanyDetails = lazy(() =>
  import("./components/manageCompany/ManageComapany")
);
const AddCompanyDocumentDetails = lazy(() =>
  import("./components/manageCompany/AddCompDocument")
);
const UpdateCompanyDocumentDetails = lazy(() =>
  import("./components/manageCompany/UpdateCompDocument")
);
const AddCompanyDetails = lazy(() =>
  import("./components/manageCompany/AddCompany")
);
const UpdateCompanyDetails = lazy(() =>
  import("./components/manageCompany/UpdateCompany")
);

const EnquiryDetailsComponent = lazy(() =>
  import("./components/manageEnquiry/EnquiryDetails")
);
const ViewEnquiryDocument = lazy(() =>
  import("./components/manageEnquiry/ViewEnquiryDocument")
);
const EditEnquiryDocument = lazy(() =>
  import("./components/manageEnquiry/EditEnquiryDocument")
);

const ReportDetailsComponent = lazy(() =>
  import("./components/manageReports/ReportDetails")
);
function App() {
  const { role } = UseAuth();
  const location = useLocation();
  const isLoginPage = location.pathname === "/";
  const isForgotPasswordPage = location.pathname === "/ForgotPassword";

  return (
    <>
      <div className="app">
        {!isLoginPage && !isForgotPasswordPage && <SideBarComponent />}
        <main className="content">
          {!isLoginPage && !isForgotPasswordPage && <TopBarComponent />}
          <Routes>
            <Route path="/">
              <Route
                index
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <LoginComponent />
                  </Suspense>
                }
              />
            </Route>
            <Route path="/ecp">
              {/* <Route index element={<Suspense fallback={<div>Loading..</div>}><DashBoardComponent /></Suspense>} /> */}
              {role === "SAdmin" ? (
                <Route
                  index
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <CompanyAdminComponent />
                    </Suspense>
                  }
                />
              ) : (
                <Route
                  index
                  element={
                    <Suspense fallback={<div>Loading..</div>}>
                      <EnquiryDetailsComponent />
                    </Suspense>
                  }
                />
              )}
              <Route
                path="CompanyAdmin"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <CompanyAdminComponent />
                  </Suspense>
                }
              />
              <Route
                path="AddCompanyAdmin"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AddCompanyAdmin />
                  </Suspense>
                }
              />
              <Route
                path="UpdateCompanyAdmin/:cid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <UpdateCompanyAdmin />
                  </Suspense>
                }
              />

              <Route
                path="DeptDetails"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <DeptDetailsComponent />
                  </Suspense>
                }
              />
              <Route
                path="AddDept"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AddDeptComponent />
                  </Suspense>
                }
              />
              <Route
                path="UpdateDept/:deptid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <UpdateDeptComponent />
                  </Suspense>
                }
              />
              <Route
                path="AddDeptDocument"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AddDocumentComponent />
                  </Suspense>
                }
              />
              <Route
                path="UpdateDocument/:docid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <UpdateDocumentComponent />
                  </Suspense>
                }
              />

              <Route
                path="ManageComapany"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <ManageCompanyDetails />
                  </Suspense>
                }
              />
              <Route
                path="AddCompDocument"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AddCompanyDocumentDetails />
                  </Suspense>
                }
              />
              <Route
                path="UpdateCompDocument/:docid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <UpdateCompanyDocumentDetails />
                  </Suspense>
                }
              />
              <Route
                path="AddCompany"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <AddCompanyDetails />
                  </Suspense>
                }
              />
              <Route
                path="UpdateCompany/:cid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <UpdateCompanyDetails />
                  </Suspense>
                }
              />

              <Route
                path="EnquiryDetails"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <EnquiryDetailsComponent />
                  </Suspense>
                }
              />
              <Route
                path="ViewEnquiryDocument"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <ViewEnquiryDocument />
                  </Suspense>
                }
              />
              <Route
                path="EditEnquiryDocument/:eid"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <EditEnquiryDocument />
                  </Suspense>
                }
              />

              <Route
                path="ReportDetails"
                element={
                  <Suspense fallback={<div>Loading..</div>}>
                    <ReportDetailsComponent />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="ForgotPassword"
              element={
                <Suspense fallback={<div>Loading..</div>}>
                  <ForgotPasswordComponent />
                </Suspense>
              }
            />
            <Route path="*" element={<div>Page Not Found..</div>} />
          </Routes>
        </main>
      </div>
    </>
  );
}

export default App;
