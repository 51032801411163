import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("ams_ecp");

// API headers including token
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});
export const loginApi = createAsyncThunk(
    "login/authlogin",
    async (userDetails) => {
        // console.log("userDetails==>", userDetails)
        let response;
        try {
            response = await BaseUrl.post(`/ecp/auth/signin`, userDetails);
            // console.log("response.data", response.data)
            return response.data;
        } catch (error) {
            console.log("error.response.data", error)
            return error.response.data;
        }
    }
);
export const getAllUserdata = createAsyncThunk(
    "users/getAllUserdata",
    async () => {
        try {
            // console.log("authtnk", getAuthHeaders())
            const response = await BaseUrl.get(`/ecp/auth/users`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            console.log(error);
            return error.response.data;
        }
    }
);

export const getSingleUserdata = createAsyncThunk(
    "users/getSingleUserdata",
    async (id) => {
        try {
            const response = await BaseUrl.get(`ecp/auth/users/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    }
);
export const createUserdata = createAsyncThunk(
    "users/createUserdata",
    async (data) => {
        try {
            const response = await BaseUrl.post(`ecp/auth/signup`, data, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            console.log(error);
            return error.response.data;
        }
    }
);
export const updateeUserdata = createAsyncThunk(
    "users/updateeUserdata",
    async (data) => {
        try {
            const response = await BaseUrl.patch(`ecp/auth/users`, data, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    }
);
// get department dropdata
export const getDeptDropdata = createAsyncThunk(
    "users/getDeptDropdata",
    async () => {
        try {
            const response = await BaseUrl.get(`ecp/department/deptDropDown`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    });
    //get departmentDropData By Sending CompanyId
    export const getDeptByCompId = createAsyncThunk(
        "users/getDeptByCompId",
        async (cid) => {
            try {
                const response = await BaseUrl.get(`ecp/department/deptByCompId/${cid}`, getAuthHeaders());
                // console.log("response.data", response.data)
                return response.data.data;
            } catch (error) {
                console.log(error);
            }
        });
//company users list
export const getUserListData = createAsyncThunk(
    "users/getUserListData",
    async (id) => {
        try {
            const response = await BaseUrl.get(`ecp/auth/listUserByCompID/${id}`, getAuthHeaders());
            // console.log("getUserListData==>", response.data.data)
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    }
);
//dept users list
export const getUserListDept = createAsyncThunk(
    "users/getUserListDept",
    async (id) => {
        try {
            const response = await BaseUrl.get(`ecp/auth/listUserByDeptID/${id}`, getAuthHeaders());
            // console.log("getUserListDept==>", response.data.data)
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    }
);
export const fetchPermissions = createAsyncThunk(
    "/ecp/fetchPermissions",
    async (data) => {
        try {
            const response = await BaseUrl.get(`/ecp/permissions`, getAuthHeaders());
            // console.log("response.data pewrmsioin: ", response.data)
            return response.data;
        } catch (error) {
            // alert(`${error.response.data.message}`)
            console.log(error);
        }
    }
);
// export const fetchPermissions = "{\"side_nav\":{\"can_manage_dashboard\":true,\"can_manage_company\":true,\"can_manage_departments\":true,\"can_manage_users\":true,\"can_manage_enquiries\":true},\"manage_company\":{\"can_add_company\":true,\"can_edit_company\":true,\"can_delete_company\":true,\"can_view_company\":true},\"manage_department\":{\"can_add_department\":true,\"can_edit_department\":true,\"can_delete_department\":true,\"can_view_department\":true},\"manage_users\":{\"can_add_users\":true,\"can_edit_users\":true,\"can_delete_users\":true,\"can_view_users\":true},\"manage_enquiries\":{\"can_add_enquiry\":true,\"can_edit_enquiry\":true,\"can_delete_enquiry\":true,\"can_view_enquires\":true}}"
export const forgotPassword = createAsyncThunk(
    "/ecp/forgotPassword",
    async (userDetails) => {
        console.log("user forgot password details===>", userDetails)
        try {
            const response = await BaseUrl.patch('/ecp/auth/forgetPassword', userDetails);
            // console.log("response.data forgotPassword: ", response.data)
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);

// login SLICE
export const loginSlice = createSlice({
    name: "auth",
    initialState: {
        userData: [],
        singleUserData: {},
        userList: [],
        deptUserList: [],
        dropData: [],
        permission: "",
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllUserdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllUserdata.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;
            })
            .addCase(getAllUserdata.rejected, (state, action) => {
                state.loading = true;
                state.error = action.error;
            });
        builder
            .addCase(getSingleUserdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleUserdata.fulfilled, (state, action) => {
                state.loading = false;
                // state.singleUserData = action.payload.userData;
                state.singleUserData = action.payload;
            })
            .addCase(getSingleUserdata.rejected, (state, action) => {
                state.loading = true;
                state.error = action.error;
            });
        builder
            .addCase(getDeptDropdata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDeptDropdata.fulfilled, (state, action) => {
                state.loading = false;
                state.dropData = action.payload;
            })
            .addCase(getDeptDropdata.rejected, (state, action) => {
                state.loading = true;
                state.error = action.error;
            });
        //comp
        builder
            .addCase(getUserListData.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserListData.fulfilled, (state, action) => {
                state.loading = false;
                state.userList = action.payload;
            })
            .addCase(getUserListData.rejected, (state, action) => {
                state.loading = true;
                state.error = action.error;
            });
        //dept
        builder
            .addCase(getUserListDept.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserListDept.fulfilled, (state, action) => {
                state.loading = false;
                state.deptUserList = action.payload;
            })
            .addCase(getUserListDept.rejected, (state, action) => {
                state.loading = true;
                state.error = action.error;
            });
        builder
            .addCase(fetchPermissions.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchPermissions.fulfilled, (state, action) => {
                state.loading = false;
                state.permission = action.payload;
            })
            .addCase(fetchPermissions.rejected, (state, action) => {
                state.loading = true;
                state.error = action.error;
            });
            builder
            .addCase(getDeptByCompId.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDeptByCompId.fulfilled, (state, action) => {
                state.loading = false;
                state.dropData = action.payload;
            })
            .addCase(getDeptByCompId.rejected, (state, action) => {
                state.loading = true;
                state.error = action.error;
            });
    },
});

// SELECTORS
export const getUsersData = (state) => state.auth.userData;
export const getUsersList = (state) => state.auth.userList;
export const getDeptUsersList = (state) => state.auth.deptUserList;
export const getSingleUsersData = (state) => state.auth.singleUserData;
export const getDepartmentDropData = (state) => state.auth.dropData;
export const getPermissions = (state) => state.auth.permission;
export const loginerror = (state) => state.auth.error;

// EXPORTS
export default loginSlice.reducer;