import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";
const getToken = () => localStorage.getItem("ams_ecp");
// API headers including token
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});
export const getAllEnquirydata = createAsyncThunk(
    "enquiry/getAllEnquirydata",
    async () => {
        try {
            // console.log("authtnk", getAuthHeaders())
            const response = await BaseUrl.get(`ecp/enquiry`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            return error.response.data;
            console.log(error);
        }
    }
);
export const getSingleEnquirydata = createAsyncThunk(
    "enquiry/getSingleEnquirydata",
    async (id) => {
        try {
            // console.log("authtnk", getAuthHeaders())
            const response = await BaseUrl.get(`/ecp/enquiry/${id}`, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data[0];
        } catch (error) {
            return error.response.data;
            console.log(error);
        }
    }
);
export const UpdateEnquirydata = createAsyncThunk(
    "enquiry/UpdateEnquirydata",
    async (data) => {
        try {
            // console.log("authtnk==>", getAuthHeaders())
            const response = await BaseUrl.patch(`/ecp/enquiry/`, data, getAuthHeaders());
            // console.log("response.data", response.data)
            return response.data.data;
        } catch (error) {
            return error.response.data;
            console.log(error);
        }
    }
);

export const enquirySlice = createSlice({
    name: "enquiry",
    initialState: {
        enquiryData: [],
        singleEnquiryData: {},
        loading: false,
        error: null,

    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllEnquirydata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAllEnquirydata.fulfilled, (state, action) => {
                state.loading = false;
                state.enquiryData = action.payload;
            })
            .addCase(getAllEnquirydata.rejected, (state, action) => {
                state.loading = true;
                state.error = action.error;
            });
        builder
            .addCase(getSingleEnquirydata.pending, (state) => {
                state.loading = true;
            })
            .addCase(getSingleEnquirydata.fulfilled, (state, action) => {
                state.loading = false;
                state.singleEnquiryData = action.payload;
            })
            .addCase(getSingleEnquirydata.rejected, (state, action) => {
                state.loading = true;
                state.error = action.error;
            });
    },
});
export const getEnquiryData = (state) => state.enquiry.enquiryData;
export const getSinglenquiryData = (state) => state.enquiry.singleEnquiryData;
export const loginerror = (state) => state.enquiry.error;
export default enquirySlice.reducer;