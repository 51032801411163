import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "../../BaseUrl";

// Function to retrieve token from localStorage
const getToken = () => localStorage.getItem("ams_ecp");

// API headers including token
const getAuthHeaders = () => ({
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
});

//dept users list
export const getUsersOnDeptID = createAsyncThunk(
    "report/getUsersOnDeptID",
    async (id) => {
        try {
            const response = await BaseUrl.get(`ecp/auth/listAllUserByDeptID/${id}`, getAuthHeaders());
            // console.log("getUsersOnDeptID==>", response.data.data)
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    }
);

//generate report
export const generateEnquiryReports = createAsyncThunk(
    "report/generateEnquiryReports",
    async (data) => {
        try {
            const response = await BaseUrl.post(`ecp/enquiry/reports/`, data, getAuthHeaders());
            // console.log("generateEnquiryReports==>", response)
            return response.data.data;
        } catch (error) {
            console.log(error);
        }
    }
);

export const reportSlice = createSlice({
    name: "report",
    initialState: {
        deptUserList: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUsersOnDeptID.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUsersOnDeptID.fulfilled, (state, action) => {
                state.loading = false;
                state.deptUserList = action.payload;
            })
            .addCase(getUsersOnDeptID.rejected, (state, action) => {
                state.loading = true;
                state.error = action.error;
            });
    },
});
export const getUsersOnDeptIDList = (state) => state.report.deptUserList;
export const loginerror = (state) => state.report.error;

// EXPORTS
export default reportSlice.reducer;