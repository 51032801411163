import { Box, Typography } from '@mui/material'
import React from 'react'
import { UseAuth } from '../customhooks/UseAuth';

const TopBarComponent = () => {

    const { name, role, department_name, company_name } = UseAuth();

    // console.log("role===>", UseAuth())
    const styles = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItem: "center",
        position: "sticky",
        top: "0px",
        zIndex: 99,
        boxSadhow: "5px 0 10px black",
        p: 1
    }
    return (
        <>
            {/* <Grid container sx={styles} bgcolor={"white"}>
                <Grid item xs={2} sm={2} md={2} xl={2}>
                    <Box sx={styles1}>
                        <Avatar style={{ boxShadow: "0 0 5px black" }} src='/samyojak_logo.jpeg' alt='samyojak_logo.jpeg' />
                    </Box>
                </Grid>
                <Grid item xs={8} sm={8} md={8} xl={8}>
                    <Typography variant='h6'>
                        Welcome to Samyojak
                    </Typography>
                </Grid>
                <Grid item xs={2} sm={2} md={2} xl={2}>
                    <Box sx={styles1}>
                        <Typography variant='h6'>
                            Welcome to Samyojak
                        </Typography>
                    </Box>

                </Grid>
            </Grid> */}
            <Box sx={styles} bgcolor={"white"} borderBottom={"2px lightgrey solid"}>
                {/* <Avatar style={{ width: 60, height: 60, boxShadow: "0 0 5px black" }} src='/samyojak_logo.jpeg' alt='samyojak_logo.jpeg' /> */}
                <div>
                    <Typography fontSize={"1.4em"} fontWeight='bold' color={"#1976D2"}>
                        Welcome to Advanced Micro Services Pvt.Ltd
                    </Typography>
                    <Typography variant='h6' fontWeight={'bold'} color={"#cf2e2e"}>
                        Enquiry Capture Pro
                    </Typography>
                </div>
                <div style={{ direction: 'rtl' }}>
                    {/* <br /> */}
                    <Typography variant='body' fontSize={"1.2em"} color={"#1976D2"} fontWeight={"bold"}>
                        {name}
                    </Typography>
                    <br />
                    <Typography variant='body' fontSize={"1.0em"} color={"#cf2e2e"} fontWeight={"bold"}>
                        {role === "SAdmin" ? "Super Admin" :
                            role === "CAdmin" ? "Company Admin" :
                                role === "DAdmin" ? "Department Admin" :
                                    role === "DUser" ? "Department User" : ""}
                    </Typography>
                    <br />
                    {(role === "DAdmin" || role === "DUser") && (
                        <Typography variant='body' fontSize={"1.1em"} color={"#1976D2"} fontWeight={"bold"}>
                            {department_name}
                        </Typography>
                    )}

                    {role === "CAdmin" && (
                        <Typography variant='body' fontSize={"1.1em"} color={"#1976D2"} fontWeight={"bold"} >
                            {company_name}
                        </Typography>
                    )}

                </div>

            </Box >
        </>
    )
}

export default TopBarComponent