import { jwtDecode } from "jwt-decode";
import { Navigate } from "react-router-dom";

export const UseAuth = () => {


    const token = localStorage.getItem("ams_ecp");
    // console.log("token==>", token)
    if (!token) {
        return <Navigate to='/' />
    }
    if (token) {
        try {
            const decode = jwtDecode(token);
            const { id, name, email, company_id, role, department_name, company_name, is_report,department_id } = decode;
            // console.log("user data ==>>", decode)
            return { userId: id, name: name, email, company_id: company_id, department_id: department_id, role, department_name: department_name, company_name: company_name, is_report: is_report,token: token };

        } catch (error) {

            console.error("Error decoding token:", error);
        }
    }
}